import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 1.875rem;

  max-width: 2060px;
  width: 100%;
  margin: 0 auto 4rem;

  h3 {
    margin-top: 4rem;
    font-size: 2rem;
    color: #444;
  }
`;

export const WelcomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  .first-access {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    .spinner-border {
      width: 6.25rem;
      height: 6.25rem;
    }
  }
`;

export const CompareMunicipalPrices = styled.div`
  width: 100%;

  .title-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @media (max-width: 760px) {
      flex-direction: column;
    }
  }

  .title-filter .title-filter__left {
    width: 50%;

    h6 {
      font-size: 20px;
      font-weight: bold;
      color: var(--dark);
    }
    span {
      color: var(--dark);
      font-size: 16px;
    }

    @media (max-width: 760px) {
      width: 100%;
    }
  }

  .title-filter .title-filter__right {
    display: flex;
    gap: 15px;
    width: 50%;

    .filter-input {
      width: 100%;

      label {
        display: block;
        color: #444;
        font-size: 14px;
        margin-bottom: 5px;
      }

      select,
      input {
        padding: 0 9px;
        width: 100%;
        height: 36px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        outline: none;
      }
    }

    @media (max-width: 760px) {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .compare-prices {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
    @media (max-width: 760px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 460px) {
      grid-template-columns: 1fr;
    }
  }
  .compare-prices .municipality-price {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.30);
    background: #FFF;

    select {
      width: 100%;
      padding: 8px 10px;
      border-top: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.30);
      border-right: none;
      border-left: none;
      outline: none;
    }

    .price {
      padding: 20px 15px 11px;

      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: none;
        font-weight: 500;

        img {
          margin-left: auto;
        }
      }

      span {
        color: #444;
        font-size: 12px;
      }
    }
  }

  h4 {
    font-weight: 600;
    margin-top: 2rem;
  }

  .teams {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    border: 1px solid rgba(0,0,0, .3);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 2rem;
    box-shadow: 2px 5px 25px rgba(0,0,0, .03);

    .team-single {

      &:not(:first-child) {
        border-left: 1px dashed rgba(0,0,0, .3);
        padding-left: 1rem;
      }

      .title-head {
        display: flex;
        justify-content: space-between;
        h6 {
          margin-bottom: 1rem;
          font-weight: 600;
        }
      }
      ul {
        padding-left: 0;
        list-style: none;
        
        li {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px dashed rgba(0,0,0, .3);
        }
      }
    }

    @media (max-width: 760px) {
      grid-template-columns: 1fr;

      .team-single {

        &:not(:first-child) {
          border-left: none;
          padding-left: 0;
        }
      }
    }
  }

  .chart-line {
    margin-top: 2rem;
  }
`;

export const ContentHome = styled.div`
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h5 {
    font-size: 1.5rem;
    margin-bottom: 3rem;

    span {
      font-weight: bold;
    }
  }

  h6.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark);
  }
`;

