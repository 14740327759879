'use client'
import React, { useEffect } from 'react';

import {
  Container,
  ContentHome,
  WelcomeContainer
} from './styled';
import { useMain } from '@/hooks/main';
import { Spinner } from 'react-bootstrap';
import dynamic from 'next/dynamic';

export default function Home() {
  const {
    setOpenOrCloseDropdown,
    infoUser,
  } = useMain();

  const DynamicPriceCompare = dynamic(() => import('@/components/PriceCompare'), { ssr: false });
  // const DynamicClimateToday = dynamic(() => import('@/components/ClimateToday'), { ssr: false });
  const DynamicPredictionClimateMunicipality = dynamic(() => import('@/components/PredictionClimateMunicipality'), { ssr: false });

  useEffect(() => {
    setOpenOrCloseDropdown(false);
  }, []);

  return (
    <Container>
      { (Object.keys(infoUser).length === 0 && !infoUser.has_team)
        ? <WelcomeAglut />
        : <ContentHome>
          <h5>Seja bem-vindo, <span>{infoUser.user?.first_name + ' ' + infoUser.user?.last_name}</span></h5>

          <DynamicPriceCompare />

          <DynamicPredictionClimateMunicipality/>
        </ContentHome>
      }
    </Container>
  )
}


function WelcomeAglut() {
  return (
    <WelcomeContainer>
      <h3>Bem vindo ao Aglut.</h3>
      <h5>A plataforma de gerenciamento para otimizar seus processos, aumentar a produtividade da sua equipe e impulsionar seus resultados.</h5>
      <div className="first-access">
        <h4>Carregando seu acesso ao sistema...</h4>
        <Spinner animation="border" />
      </div>

    </WelcomeContainer>
  )
}


